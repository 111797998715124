

import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";

import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import SortingModel from "@/store/entities/sortingmodel";
import AbpBase from "@/lib/abpbase";
import CreateJobForm from "@/views/setting/jobcontext/job/job-create.vue";
import JobPageRequest from "@/store/entities/jobcontext/pagedrequest/jobpagerequest";
import ConsolidationLevelJobRequestForm
  from "@/views/setting/jobcontext/job/jobpagedrequest/consolidationleveljobrequest.vue";
import JobLevelJobRequestForm from "@/views/setting/jobcontext/job/jobpagedrequest/jobleveljobrequest.vue";

@Component({components: {CreateJobForm, ConsolidationLevelJobRequestForm, JobLevelJobRequestForm}})
export default class Job extends AbpBase {

  async create() {
    this.createModalShow = true;
  }

  enableAdvancedSearch() {
    if (this.isAdvancedSearchEnabled) {
      this.pagerequest.clearInput();
    }
    this.isAdvancedSearchEnabled = !this.isAdvancedSearchEnabled;
  }

  pagerequest: JobPageRequest = new JobPageRequest();
  createModalShow: boolean = false;
  isAdvancedSearchEnabled: boolean = false;
  private clients: any;
  private suppliers: any;
  private consignees: any;
  private origins: any;
  private destinations: any;
  private nodeList: any;
  private completedStatuses: any;
  private tags: any;

  data() {
    return {
      clients: [],
      completedStatuses: [],
      suppliers: [],
      consignees: [],
      origins: [],
      destinations: [],
      nodeList: [],
      tags: []
    }
  }

  tagChanged(id) {
    if (this.pagerequest.tags === undefined || this.pagerequest.tags === null) {
      this.pagerequest.tags = new Array<number>();
    }
    let elementIndex = this.pagerequest.tags.indexOf(id)
    if (elementIndex === -1) {
      this.pagerequest.tags.push(id)
    } else {
      this.pagerequest.tags.splice(elementIndex, 1);
    }
  }

  async unlock(jobId, lockeBy) {
    this.$Modal.confirm({
      title: this.L('Tips'),
      content: this.L('This job is locked by ' + lockeBy + '. Are You sure to unlock it?'),
      okText: this.L('Yes'),
      cancelText: this.L('No'),
      onOk: async () => {
        await this.$store.dispatch({type: 'job/unlock', data: jobId})
        await this.getPage();
      }
    })
  }

  async lock(jobId) {
    await this.$store.dispatch({
      type: 'job/lock',
      data: jobId
    })
    await this.getPage();
  }

  async getTradeTransportLocations(keyWord: string) {
    if (keyWord !== null && keyWord.length > 2) {
      return await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(keyWord, -1));
    }
    return [];
  }

  get list() {
    return this.$store.state.job.list;
  };

  async search() {
    this.$store.commit('job/setCurrentPage', 1);
    await this.getPage();
  }

  get loading() {
    return this.$store.state.job.loading;
  }

  pageChange(page: number) {
    this.$store.commit('job/setCurrentPage', page);
    this.getPage();
  }

  pageSizeChange(pageSize: number) {
    this.$store.commit('job/setPageSize', pageSize);
    this.getPage();
  }

  async cloneJob(jobId: number) {
    await this.$store.dispatch({
      type: 'job/cloneJob',
      data: jobId
    })
    await this.getPage();
  }

  async edit(id: number) {
    await this.$router.push({name: 'job-form', params: {jobId: id.toString()}});
    await this.getPage();
  }

  async clientView(id: number) {
    await this.$router.push({name: 'job-client-form', params: {jobId: id.toString()}});
    await this.getPage();
  }

  async history(id: number) {
    await this.$router.push({name: 'job-audit', params: {jobId: id.toString()}});
    await this.getPage();
  }

  async loadClients(keyWord: string) {
    this.clients = await this.loadCompanies(keyWord);
  }

  async loadOrigins(keyWord: string) {
    this.origins = await this.getTradeTransportLocations(keyWord);
  }

  async loadDestinations(keyWord: string) {
    this.destinations = await this.getTradeTransportLocations(keyWord);
  }

  async loadCompanies(keyWord) {
    if (keyWord !== null && keyWord.length > 2) {
      return await this.$store.dispatch('company/getCompaniesKeyWordLookUp', keyWord);
    } else return [];
  }

  async showJob(currentRow) {
    if (!this.checkPermissions('Pages.EditJob')) {
      await this.$router.push({name: 'job-client-form', params: {jobId: currentRow.id.toString()}});
    } else {
      await this.$router.push({name: 'job-form', params: {jobId: currentRow.id.toString()}});
    }
    await this.getPage();
  }

  async getPage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'job/getAll',
      data: this.pagerequest
    })
  }

  get pageSize() {
    return this.$store.state.job.pageSize;
  }

  get totalCount() {
    return this.$store.state.job.totalCount;
  }

  get currentPage() {
    return this.$store.state.job.currentPage;
  }

  async changeSort(data) {
    if (this.pagerequest.sorting == null) {
      this.pagerequest.sorting = [];
    }
    let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
    if (item == null) {
      let sortingModel = new SortingModel();
      sortingModel.fieldName = data.key;
      sortingModel.isDescendingDirection = data.order === "desc";
      this.pagerequest.sorting.push(sortingModel)
    } else {
      item.isDescendingDirection = data.order === "desc";
    }
    await this.getPage()
  }

  private columns;

  checkPermissions(permission: string) {
    return window.abp.auth.hasPermission(permission);
  }

  hasAddPermission() {
    return this.hasPermission("Pages.Job.Add");
  }


  getClientColumns() {
    return [{
      title: this.L('PO'),
      key: 'po',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Tag'),
      key: 'tag',
      width: 100
    }, {
      title: this.L('Supplier'),
      key: 'supplier',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Consignee'),
      key: 'consignee',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('ETD'),
      key: 'etd',
      width: 150,
      sortable: 'custom',
      render: (h: any, params: any) => {
        return h('span', params.row.etd === null ? "" : new Date(params.row.etd).toLocaleDateString())
      }
    }, {
      title: this.L('ETA'),
      key: 'eta',
      width: 150,
      sortable: 'custom',
      render: (h: any, params: any) => {
        return h('span', params.row.eta === null ? "" : new Date(params.row.eta).toLocaleDateString())
      }
    }, {
      title: this.L('Origin'),
      key: 'origin',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Destination'),
      key: 'destination',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Status'),
      key: 'node',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Shipping method'),
      key: 'shippingMethod',
      width: 170
    }, {
      title: this.L('ShippingOrder'),
      key: 'shippingOrderNumber',
      sortable: 'custom',
      width: 160
    }, {
      title: this.L('EST DELIVERY DATE'),
      key: 'estDeliveryDate',
      sortable: 'custom',
      width: 160,
      render: (h: any, params: any) => {
        return h('span', params.row.estDeliveryDate === null ? "" : new Date(params.row.estDeliveryDate).toLocaleDateString())
      }
    }, {
      title: this.L('Est. Freight Cost'),
      key: 'estFreightCost',
      sortable: 'custom',
      width: 160
    }, {
      title: this.L('Final Freight cost'),
      key: 'finalFreightCost',
      sortable: 'custom',
      width: 160
    }, {
      title: this.L('Incoterm'),
      key: 'incoterm',
      sortable: 'custom',
      width: 160
    }, {
      title: this.L('Completed'),
      key: 'isCompleted',
      width: 140
    }, {
      title: this.L('Cons'),
      fixed: 'right',
      sortable: 'custom',
      key: 'consolidationNumber',
      width: 100
    }, {
      title: this.L('#'),
      fixed: 'right',
      key: 'number',
      width: 100,
      sortable: 'custom',
      tree: true
    }]
  }

  getAdminColumns() {
    return [{
      title: this.L('#'),
      fixed: 'left',
      key: 'number',
      width: 100,
      sortable: 'custom',
      tree: true
    }, {
      title: this.L('Cons'),
      fixed: 'left',
      sortable: 'custom',
      key: 'consolidationNumber',
      width: 100
    }, {
      title: this.L('Tag'),
      key: 'tag',
      width: 100
    }, {
      title: this.L('Locked by'),
      key: 'lockedBy',
      width: 150
    }, {
      title: this.L('Client'),
      key: 'client',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Supplier'),
      key: 'supplier',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Consignee'),
      key: 'consignee',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('PO'),
      key: 'po',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('ETD'),
      key: 'etd',
      width: 150,
      sortable: 'custom',
      render: (h: any, params: any) => {
        return h('span', params.row.etd === null ? "" : new Date(params.row.etd).toLocaleDateString())
      }
    }, {
      title: this.L('ETA'),
      key: 'eta',
      width: 150,
      sortable: 'custom',
      render: (h: any, params: any) => {
        return h('span', params.row.eta === null ? "" : new Date(params.row.eta).toLocaleDateString())
      }
    }, {
      title: this.L('Origin'),
      key: 'origin',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Destination'),
      key: 'destination',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Node'),
      key: 'node',
      sortable: 'custom',
      width: 150
    }, {
      title: this.L('Shipping method'),
      key: 'shippingMethod',
      width: 170
    }, {
      title: this.L('ShippingOrder'),
      key: 'shippingOrderNumber',
      sortable: 'custom',
      width: 160
    }, {
      title: this.L('Actions'),
      slot: 'action',
      fixed: 'right',
      width: 360
    }, {
      title: this.L('EST DELIVERY DATE'),
      key: 'estDeliveryDate',
      sortable: 'custom',
      width: 160,
      render: (h: any, params: any) => {
        return h('span', params.row.estDeliveryDate === null ? "" : new Date(params.row.estDeliveryDate).toLocaleDateString())
      }
    }, {
      title: this.L('Completed'),
      key: 'isCompleted',
      width: 140
    }]
  }

  async created() {
    if (this.checkPermissions('Pages.EditJob')) {
      this.columns = this.getAdminColumns();
    } else {
      this.columns = this.getClientColumns();
    }
    this.nodeList = await this.$store.dispatch('node/lookUp');
    this.completedStatuses = [{id: null, text: 'All'}, {id: 1, text: 'Yes'}, {id: 0, text: 'No'}];
    await this.getPage();
    this.tags = await this.$store.dispatch('tag/lookUp');
  }

  async handleLoadData(item, callback) {
    let request = this.pagerequest.getCopy();
    request.consolidationId = item.id;
    const data = await this.$store.dispatch({
      type: 'job/getJobsOfConsolidation',
      data: request
    })
    callback(data);
  }
}
