export default class PagedConsolidationLevelJobResultRequest {
    originAgentId: number;
    destinationAgentId: number;
    portOfLoadingId: number;
    portOfDischargeId: number;
    insurancePolicyNumber: string;
    shippingMethodId: number;
    vesselId: number;
    lloyds: string;
    voyage: string;
    flight: string;
    airlineId: number;
    carrierCode: string;
    etd: Date;
    eta: Date;
    atd: Date;
    ata: Date;
    originShippingLineId: number;
    originColoaderId: number;
    consoleDestinationColoaderId: number;
    destinationShippingLineId: number;
    transportCompanyId: number;
    originCustomBrokerId: number;
    destinationCustomsBrokerId: number;
    transportCompanyDestinationId: number;
    cargoReportingAgentId: number;
    bookingNumberConsole: string;
    containerRelease: string;
    originTransportReference: string;
    consoleExportClearanceNumber: string;
    obl: string;
    mbl: string;
    mawb: string;
    destinationTransportReference: string;
}
