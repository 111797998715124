import { render, staticRenderFns } from "./job.vue?vue&type=template&id=5b59b9ac"
import script from "./job.vue?vue&type=script&lang=ts"
export * from "./job.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports