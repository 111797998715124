

    import {Component, Prop} from "vue-property-decorator";
    import AbpBase from "../lib/abpbase";

    @Component
    export default class FilterableSelect extends AbpBase {
        @Prop({default: null}) value: number;
        @Prop() loadMethod;
        private items: any;

        data() {
            return {
                items: []
            }
        }

        async mounted(){
            this.items = await this.loadMethod();
        }

        get inputValue() {
            return this.value;
        }

        set inputValue(newValue) {
            this.$emit('input', newValue);
        }
    }
